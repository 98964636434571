.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.questions {
  display: flex;
  flex-direction: row;
  /* padding: 2rem; */
}

.leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  display: flex;
  margin: 1rem 0;
  background-color: wheat;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

  padding: 1.5rem 1rem;
}
.userpic {
  border-radius: 50%;
  padding: 0 1rem;
  height: 100%;
}
.textflex {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}

.badge {
  background-color: brown;
  color: aliceblue;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: auto;
  align-items: center;
}
.score {
  padding-left: 2rem;
  font-size: 0.8rem;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* CHARTS START */
.percentage {
	font-size: 0.8em;
	line-height: 1;
	text-transform: uppercase;
	width: 100%;
	height: 10px;
	margin-left: 0px;
	background: repeating-linear-gradient(
		to right,
		#ddd,
		#ddd 1px,
		#fff 1px,
		#fff 5%
	);
}
.active {
	color: orangered;
	background-color: wheat;
	border-radius: 20%;
}
.percentage:after {
	content: '';
	display: block;
	background-color: #22a54e;
	width: 50px;
	margin-bottom: 10px;
	height: 90%;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	transition: background-color 0.3s ease;
	cursor: pointer;
}
.percentage:hover:after,
.percentage:focus:after {
	background-color: #aaa;
}

.centeredSelect > div {
	display: flex;
	align-items: center;
}
.percentage0:after {
	width: 0%;
}
.percentage1:after {
	width: 1%;
}
.percentage2:after {
	width: 2%;
}
.percentage3:after {
	width: 3%;
}
.percentage4:after {
	width: 4%;
}
.percentage5:after {
	width: 5%;
}
.percentage6:after {
	width: 6%;
}
.percentage7:after {
	width: 7%;
}
.percentage8:after {
	width: 8%;
}
.percentage9:after {
	width: 9%;
}
.percentage10:after {
	width: 10%;
}
.percentage11:after {
	width: 11%;
}
.percentage12:after {
	width: 12%;
}
.percentage13:after {
	width: 13%;
}
.percentage14:after {
	width: 14%;
}
.percentage15:after {
	width: 15%;
}
.percentage16:after {
	width: 16%;
}
.percentage17:after {
	width: 17%;
}
.percentage18:after {
	width: 18%;
}
.percentage19:after {
	width: 19%;
}
.percentage20:after {
	width: 20%;
}
.percentage21:after {
	width: 21%;
}
.percentage22:after {
	width: 22%;
}
.percentage23:after {
	width: 23%;
}
.percentage24:after {
	width: 24%;
}
.percentage25:after {
	width: 25%;
}
.percentage26:after {
	width: 26%;
}
.percentage27:after {
	width: 27%;
}
.percentage28:after {
	width: 28%;
}
.percentage29:after {
	width: 29%;
}
.percentage30:after {
	width: 30%;
}
.percentage31:after {
	width: 31%;
}
.percentage32:after {
	width: 32%;
}
.percentage33:after {
	width: 33%;
}
.percentage34:after {
	width: 34%;
}
.percentage35:after {
	width: 35%;
}
.percentage36:after {
	width: 36%;
}
.percentage37:after {
	width: 37%;
}
.percentage38:after {
	width: 38%;
}
.percentage39:after {
	width: 39%;
}
.percentage40:after {
	width: 40%;
}
.percentage41:after {
	width: 41%;
}
.percentage42:after {
	width: 42%;
}
.percentage43:after {
	width: 43%;
}
.percentage44:after {
	width: 44%;
}
.percentage45:after {
	width: 45%;
}
.percentage46:after {
	width: 46%;
}
.percentage47:after {
	width: 47%;
}
.percentage48:after {
	width: 48%;
}
.percentage49:after {
	width: 49%;
}
.percentage50:after {
	width: 50%;
}
.percentage51:after {
	width: 51%;
}
.percentage52:after {
	width: 52%;
}
.percentage53:after {
	width: 53%;
}
.percentage54:after {
	width: 54%;
}
.percentage55:after {
	width: 55%;
}
.percentage56:after {
	width: 56%;
}
.percentage57:after {
	width: 57%;
}
.percentage58:after {
	width: 58%;
}
.percentage59:after {
	width: 59%;
}
.percentage60:after {
	width: 60%;
}
.percentage61:after {
	width: 61%;
}
.percentage62:after {
	width: 62%;
}
.percentage63:after {
	width: 63%;
}
.percentage64:after {
	width: 64%;
}
.percentage65:after {
	width: 65%;
}
.percentage66:after {
	width: 66%;
}
.percentage67:after {
	width: 67%;
}
.percentage68:after {
	width: 68%;
}
.percentage69:after {
	width: 69%;
}
.percentage70:after {
	width: 70%;
}
.percentage71:after {
	width: 71%;
}
.percentage72:after {
	width: 72%;
}
.percentage73:after {
	width: 73%;
}
.percentage74:after {
	width: 74%;
}
.percentage75:after {
	width: 75%;
}
.percentage76:after {
	width: 76%;
}
.percentage77:after {
	width: 77%;
}
.percentage78:after {
	width: 78%;
}
.percentage79:after {
	width: 79%;
}
.percentage80:after {
	width: 80%;
}
.percentage81:after {
	width: 81%;
}
.percentage82:after {
	width: 82%;
}
.percentage83:after {
	width: 83%;
}
.percentage84:after {
	width: 84%;
}
.percentage85:after {
	width: 85%;
}
.percentage86:after {
	width: 86%;
}
.percentage87:after {
	width: 87%;
}
.percentage88:after {
	width: 88%;
}
.percentage89:after {
	width: 89%;
}
.percentage90:after {
	width: 90%;
}
.percentage91:after {
	width: 91%;
}
.percentage92:after {
	width: 92%;
}
.percentage93:after {
	width: 93%;
}
.percentage94:after {
	width: 94%;
}
.percentage95:after {
	width: 95%;
}
.percentage96:after {
	width: 96%;
}
.percentage97:after {
	width: 97%;
}
.percentage98:after {
	width: 98%;
}
.percentage99:after {
	width: 99%;
}
.percentage100:after {
	width: 100%;
}

/* CHARTS END */

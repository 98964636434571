* {
  font-family: "Roboto", sans-serif;
}

.container {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  justify-content: center;
  align-items: center;
  justify-items: start;
  background-color: wheat;
  padding: 2rem;
  max-width: 40%;
  min-width: 20rem;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.q__details__header {
  grid-row: 2/3;
  width: 100%;
  position: relative;
}

.q__userpic {
  border-radius: 50%;
  padding: 0 1rem;
  height: 100%;
  display: grid;
  justify-self: center;
}
@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.votes {
  font-size: 0.8rem;
}
.optioninput {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.optioninput:hover {
  background: #9faab7;
}
.optioninput:checked {
  background: #40e0d0;
}
.optioninput:checked::before {
  height: 40px;
  width: 40px;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 40px;
}
.optioninput:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}
.optioninput.radio {
  border-radius: 50%;
}
.optioninput.radio::after {
  border-radius: 50%;
}

.questions__tableline {
  position: relative;
}
.yourVote {
  font-size: 0.6rem;
  position: absolute;
  top: 10%;
  left: -3.5rem;
  background-color: #22a54e;
  border-radius: 20%;
  height: auto;
  width: 3rem;
  text-align: center;
  transform: rotate(-0deg);
}

.form {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  width: 100%;
}
.btn__container {
  display: grid;
  justify-items: center;
  width: 100%;
}
.glow {
  width: 120px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin-top: 1.5rem;
}

.glow:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow:active {
  color: #000;
}

.glow:active:after {
  background: transparent;
}

.glow:hover:before {
  opacity: 1;
}

.glow:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

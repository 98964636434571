.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
}
.container h4 {
  margin-top: 6rem;
  padding: 2rem 10rem;
  text-align: center;
  line-height: 2rem;
}
.loginBtn {
  margin: 2rem;
  padding: 2rem;
}

.userpic {
  border-radius: 50%;
  padding: 0 1rem;
  width: 10%;
}
ul {
  list-style: none;
}

.form {
  padding-left: 3rem;
}
.form__group:not(:last-child) {
  margin-bottom: 3rem;
}
.form__input {
  font-size: 1.5rem;
  border: none;
  color: inherit;
  font-family: inherit;
  outline: none;
  display: block;
  width: 80%;
  border-bottom: 3px solid;
  padding: 1rem 1.5rem;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
}
.form__input:focus {
  outline: none;
  border-bottom: 3px solid #000;
  box-shadow: 0 1rem 2rem #000;
}
.form__input:focus:invalid {
  border-bottom: 3px solid red;
}
.form__input::-webkit-input-placeholder {
  color: #ccc;
  font-weight: 400;
}
.form__label {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
  transition: all 0.3s;
  display: block;
  transform: translateY(-7.5rem);
}
.form__input:placeholder-shown + .form__label {
  opacity: 0;
  font-size: 1rem;
  font-weight: 400;
  visibility: hidden;
  transform: translateY(-4rem);
}
.form__radio-group {
  width: 35%;
  display: inline-block;
}
.form__radio-input {
  content: "";
}
.form__radio-label {
  cursor: pointer;
  position: relative;
  padding-left: 4rem;
}
.form__radio-button {
  width: 3rem;
  height: 3rem;
  border: 5px solid #000;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.4rem;
}
.form__radio-button::after {
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  opacity: 0;
  transition: all 0.5s;
}
.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
  opacity: 1;
}

/* New Styles */
.form {
  width: auto;
  float: left;
  margin: 20px;
}
.form > div {
  position: relative;
  overflow: hidden;
}
.form input,
.form textarea {
  border: 2px solid gray;
  background: none;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 8px 12px;
  outline: 0;
}
.form input:valid,
.form textarea:valid {
  background: white;
}
.form input:focus,
.form textarea:focus {
  border-color: #f06d06;
}
.form input:focus + label,
.form textarea:focus + label {
  background: #f06d06;
  color: white;
  font-size: 70%;
  padding: 1px 6px;
  z-index: 2;
  text-transform: uppercase;
}
.form label {
  transition: background 0.2s, color 0.2s, top 0.2s, bottom 0.2s, right 0.2s,
    left 0.2s;
  position: absolute;
  color: #999;
  padding: 15px 10px;
}
.form textarea {
  display: block;
  resize: vertical;
}
.form.goBottom input,
.form.goBottom textarea {
  padding: 10px;
}
.form.goBottom label {
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}
.form.goBottom input:focus,
.form.goBottom textarea:focus {
  padding: 4px 6px 20px 6px;
}
.form.goBottom input:focus + label,
.form.goBottom textarea:focus + label {
  top: 100%;
  margin-top: -16px;
}

.input__container {
  margin: 1rem;
}
.container__center {
  display: flex;
  width: 70%;
  margin: auto;
}
.form__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: wheat;
  border-radius: 3%;
  padding: 2rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.glow {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin: 1rem 0;
}

.glow:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow:active {
  color: #000;
}

.glow:active:after {
  background: transparent;
}

.glow:hover:before {
  opacity: 1;
}

.glow:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
